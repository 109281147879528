// Packages
import React from "react";
import { graphql, Link } from "gatsby";
import { LocationMarkerIcon, UsersIcon, EmojiHappyIcon, CalendarIcon, ClockIcon } from "@heroicons/react/outline";

// Types
import { IGatsbyImageData } from "gatsby-plugin-image";
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { Product } from "~types/Shopify";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import P from "~components/typography/P";
import Grid from "~components/common/Grid";
import Hero from "~components/sections/Hero";
import SubTitle from "~components/typography/SubTitle";
import Ul from "~components/typography/Ul";
import Button from "~components/common/Button";
import H2 from "~components/typography/H2";
import Input from "~components/forms/elements/Input";
import FeatureIcon from "~components/features/FeatureIcon";
import Datenschutz from "~components/forms/elements/Datenschutz";
import ProductCard from "~components/shop/ProductCard";
import Alert from "~components/common/Alert";
import StudentsVoucher from "~components/forms/newsletter/StudentsVoucher";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	products: {
		nodes: Product[];
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const { products } = data;

	return (
		<Layout
			location={location}
			title="Einladung"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Hero element={<StudentsVoucher className="py-8 md:py-16" />} alt="" align="start">
				<SubTitle className="mb-2">Studentenrabatt!</SubTitle>

				<H2 className="mb-4">Jetzt 25% Rabatt sichern</H2>

				<Ul
					items={[
						"1-2 Semester weniger studieren",
						"Bessere Leistungen in Klausuren",
						"Aktivierung Deiner vollen Brainpower",
						"100-200% schneller Lesen und dabei X-Fach mehr merken und behalten",
						"Erhöhtes Textverständnis und Inhalte besser verstehen",
						"Prüfungsangst überwinden",
						"Intelligenter Fachwissen aneignen",
					]}
					pretty
					className="mb-3"
				/>

				<P className="mb-3">
					Hiermit bekommst Du eine einmalige Gelegenheit kostenlos an meinem Workshop: &ldquo;SpeedReading&ldquo; teil
					zunehmen.
				</P>

				<P className="mb-3">
					Lerne die Geheimnisse der Elite Universitäten kennen und entfalte Dein volles Potential im Studentenalltag
					oder im Berufsalltag.
				</P>
				<P className="mb-3">Schnell sein lohnt sich auch hier.</P>
				<P className="mb-3">Klicke auf die Homepage und trag Dich bitte verbindlich ein.</P>
				<P className="mb-10">Ich freue mich auf dieses außergewöhnliche Event mit dir.</P>
			</Hero>
			{/* <Container id="workshops">
				<div className="text-center">
					<SubTitle className="mb-2">Workshop buchen</SubTitle>
					<H2 display className="mb-16">
						Unser aktuelles Workshop-Angebot
					</H2>
				</div>

				{products.nodes.length > 0 ? (
					<Grid cols={3}>
						{products.nodes.map((product) => (
							<ProductCard key={product.title} product={product} />
						))}
					</Grid>
				) : (
					<Alert type="information" title="Keine Workshops gefunden" message={`Aktuell werden weiteren Workshop geplant. Komme gerne später wieder oder buche unseren Newsletter für weitere Informationen.`} />
				)}
			</Container> */}
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageHeader: file(relativePath: { eq: "pages/einladung/einladung_header.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 90)
			}
		}
	}
`;
