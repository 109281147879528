import React, { useState } from "react";
import Alert from "~components/common/Alert";
import Button from "~components/common/Button";
import Datenschutz from "~components/forms/elements/Datenschutz";
import Input from "~components/forms/elements/Input";
import { useAxios } from "~functions/hooks/useAxios";
import { StatusCodes } from "~types/requests/Statuscodes";
import { SipRegisterBody, SipList } from "~types/requests/SipRegister";
import { newsletter } from "~data/messages";
import SubTitle from "~components/typography/SubTitle";
import H2 from "~components/typography/H2";

interface Props {
	className: string;
}

const FormSimple: React.FC<Props> = ({ className }) => {
	const [mail, setMail] = useState("");
	const [firstName, setFirstName] = useState("");

	const body: SipRegisterBody = {
		mail,
		firstName,
		list: SipList.STUDENTS,
	};

	const { loading, error, data, operation } = useAxios({ url: "/registerSip", method: "POST", body });

	const handleRequest = (e: React.FormEvent) => {
		e.preventDefault();
		operation();
	};

	if (data?.status === StatusCodes.CREATED) {
		return (
			<Alert
				type="information"
				title="Erfolg!"
				message={`${newsletter.created} Den Gutschein findest Du nach der Bestätigung in Deinem Postfach.`}
			/>
		);
	}

	if (data?.status === StatusCodes.NO_CONTENT) {
		return (
			<Alert
				type="information"
				title="Erfolg!"
				message={`${newsletter.updated} Den Gutschein findest Du nach der Bestätigung in Deinem Postfach.`}
			/>
		);
	}

	return (
		<form name="Stunden Gutschein" onSubmit={(e) => handleRequest(e)} className={`space-y-4 ${className}`}>
			<SubTitle className="mb-2">Newsletteranmeldung</SubTitle>

			<H2 className="mb-4">Registriere Dich hier und erhalte den Gutschein</H2>

			<Input
				type="text"
				label="Vorname"
				id="firstname"
				name="Vorname"
				value={firstName}
				onChange={(e) => setFirstName(e.target.value)}
				placeholder="Max"
				autoComplete="given-name"
				required
			/>
			<Input
				type="text"
				label="E-Mail"
				id="email"
				name="E-Mail"
				value={mail}
				onChange={(e) => setMail(e.target.value)}
				placeholder="muster@gmail.com"
				autoComplete="email"
				required
			/>
			{error ? (
				<Alert
					type="warning"
					title="Server-Fehler"
					message={typeof error === "string" ? error : "Etwas ist schief gelaufen"}
				/>
			) : null}
			<Button submit text="Gutschein erhalten" className="w-full" loading={loading} />
			<Datenschutz />
		</form>
	);
};

export default FormSimple;
